import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import OlimpiadaHackeandoEcossistemas from '../components/HackeandoEcossistemas'
import Footer from '../components/FooterAgro'
import Ios from '../images/ios.svg'
import Android from '../images/android.png'
import AppMobile from '../images/app/agro-mobile.png'
import Selo from '../images/selo.png'

import {
  header,
  bg11,
  title4,
  bg7,
  desktop,
  mobile,
  contentHeader,
  contentItem
} from '../components/styles/InternalPages.styles'

import {
  titlesApp,
  titleApp,
  ctaApp,
  linksApps
} from '../components/styles/EstudeAqui.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} /> 
    <SEO title="Olimpíada Pocket Agro" />
    <NavBar location={props.location} />
    <div css={[header, contentHeader]} style={{ marginTop: 100 }}>
      <div css={contentItem}>
        <div css={bg11}>
          <img src={Selo} alt='Selo' style={{ marginBottom: 32 }} />
          <h1 style={{ textAlign: 'left' }}>HACKEANDO<br /> ECOSSISTEMAS <br/> <span style={{ color: '#f5c400' }}>31/05 A 06/07</span> </h1>
        </div>
      </div>
      <div css={contentItem}>
        <OlimpiadaHackeandoEcossistemas />
      </div>
    </div>
    <section css={[bg7, desktop]} style={{ backgroundColor: '#EEEEEE', display: 'flex', flexDirection: 'column' }}>
      <div css={titlesApp} style={{ marginTop: '-100px' }}>
        <div css={ctaApp}>NOSSO APP</div>
        <div css={titleApp}>Baixe o app das Olimpíadas Pocket e faça sua prova.</div>
      </div>
      <div css={linksApps}>
        <a href='https://apps.apple.com/br/app/olimp%C3%ADada-pocket/id1508377673' target='_blank' rel='noopener noreferrer'><img src={Ios} alt='IOs' /></a>
        <a href='https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.pocket' target='_blank' rel='noopener noreferrer'><img src={Android} alt='Android' /></a>
      </div>
    </section>
    <section css={mobile} style={{ marginTop: 100, backgroundColor: '#EEEEEE', display: 'flex', flexDirection: 'column' }}>
      <img src={AppMobile} alt='/' />
      <div style={{ textAlign: 'center', margin: '60px 0' }}>
        <div css={ctaApp}>NOSSO APP</div>
        <div css={titleApp} style={{ marginBottom: 24, padding: '0 24px' }}>Baixe o app das Olimpíadas Pocket e faça sua prova.</div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <a href='https://apps.apple.com/br/app/olimp%C3%ADada-pocket/id1508377673' target='_blank' rel='noopener noreferrer'><img src={Ios} alt='IOs' style={{ width: 200, marginBottom: 12 }} /></a>
          <a href='https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.pocket' target='_blank' rel='noopener noreferrer'><img src={Android} alt='Android' style={{ width: 200 }} /></a>
        </div>
      </div>
    </section>
    <Footer />
  </Fragment>
)

export default StudentsPage
