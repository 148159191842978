import React, { Fragment } from 'react'

import {
  inspired,
  container,
  contentView,
  columns, 
  column,
  visionBox
} from './styles/EstudeAqui.styles'

import {
  btn7,
  contentNew
} from '../components/styles/InternalPages.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired}>
      <div css={container}>
        <div css={visionBox}>
          <div css={columns}>
            <div css={column}>
              <div css={[contentView, contentNew]}>
                <h4 style={{ marginTop: 18 }}>HACKEANDO ECOSSISTEMAS</h4>
                <p style={{ marginBottom: 16, fontSize: 14 }}>Olá, estamos felizes em te receber aqui para a  primeira edição de olimpíadas pocket de 2021!</p>
                <p style={{ marginBottom: 16, fontSize: 14 }}>A Olimpíada Pocket - Hackeando Ecossistemas, tem como intuito divulgar a relevância e os princípios básicos da biologia sintética por meio de questões informativas, incentivando os estudantes a se engajarem nessa área de conhecimento.</p>
                <p style={{ marginBottom: 16, fontSize: 14 }}>A Biologia Sintética é uma ciência multidisciplinar que busca estabelecer paralelos entre os vários processos bioquímicos realizados por seres vivos e o funcionamento de máquinas e circuitos eletrônicos, a fim de construir circuitos orgânicos sintéticos de grande valor para a humanidade.</p>
                <p style={{ marginBottom: 16, fontSize: 14 }}>A Olimpíada Pocket - Hackeando Ecossistemas, é o uma iniciativa do Instituto Verte em parceria com o  iGEM USP-BR e a Olimpíada Brasileira de Biologia Sintética - OBBS, que vai ocorrer no segundo semestre de 2021.</p>
                <p style={{ marginBottom: 16, fontSize: 14 }}>Amplie seus conhecimentos sobre o assunto, inscreva-se na Olimpíada Pocket – Hackeando Ecossistemas!</p>
                <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn7} style={{ margin: '0 auto' }}>FAÇA A INSCRIÇÂO</a>
                {/* <a href='https://fractalid.fractaltecnologia.com.br/login?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn7} style={{ margin: '0 auto' }}>ACESSE A PROVA</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default InspiredSite
